// Style
import PagesBackground from "../../assets/components/pages' backgrounds/PagesBackground"
import "./contactStyle.css"

export default function Contact({ animation, fromHome }) {
    return (
        <>
        <div className={`contact ${animation}`}>
            <PagesBackground fromHome={fromHome} />
            test
        </div>
        </>
    )
}